
import { defineComponent, onMounted, ref } from "vue";
import { useForm, useField } from "vee-validate";
import { useStore } from "vuex";
import { KhoService } from "@/core/services/Kho.service";

import { CODE_REGEX } from "@/core/constants/const";
import { getErrorMsg, swalErrNotification, swalNotification } from '@/core/helpers/utils';
import { useRouterCustom } from "@/core/hooks/use-router-custom";
import {useLoading} from '@/core/hooks/use-loading';
import {setCurrentPageTitle} from '@/core/helpers/breadcrumb';
import { useButtonCustom } from '../../../../core/hooks/use-button-custom';
import { ITaiKhoanResponseBase } from '../../../../core/interfaces/ApiResponses';

import * as Yup from "yup";
import ButtonCustom from '@/components/shared/buttons/ButtonCustom.vue';
import PageRowLayout from '@/layout/_shared/PageRowLayout.vue';
import TaiKhoanSelection from '../../../../components/shared/tai-khoan-selection/TaiKhoanSelection.vue';

export default defineComponent({
  name: "new-warehouses",

	components: {
		ButtonCustom,
		PageRowLayout,
		TaiKhoanSelection,
	},

	setup() {
		const { goBack, replace } = useRouterCustom();
		const { startLoading, endLoading } = useLoading();
		const { ButtonsType, ButtonTypeColors } = useButtonCustom();

    const store = useStore();
    const firstInput = ref<null | HTMLFormElement>(null);

    const newWarehouseValidator = Yup.object({
      name: Yup.string().required('Tên kho là bắt buộc'),
      code: Yup.string()
        .matches(CODE_REGEX, 'Mã kho phải đúng định dạng')
        .required('Mã kho là bắt buộc'),
      address: Yup.string().required('Địa chỉ là bắt buộc'),
    });

    const { validate } = useForm({
      validationSchema: newWarehouseValidator,
    });

    const { value: name, errorMessage: nameErrorMess } =
      useField<string>('name');
    const { value: code, errorMessage: codeErrorMess } =
      useField<string>('code');
    const { value: note } = useField<string>('note');
    const { value: address, errorMessage: addressErrorMess } =
      useField<string>('address');

		const taiKhoanData = ref<{
			id: number;
			maTaiKhoan: string;
			maChiTiet?: string;
			tenTaiKhoan: string;
		}>({
			id: 0,
			maTaiKhoan: '',
			tenTaiKhoan: '',
		});

    onMounted(() => {
			setCurrentPageTitle('Quản lý kho');
      if (firstInput.value) {
        firstInput.value.focus();
      }
    });

		const onSubmit = async () => {
			if (!taiKhoanData.value.maChiTiet) {
				swalErrNotification(
					null,
					'Bắt buộc phải nhập đủ thông tin tài khoản',
				);

				return;
			}

			const { valid } = await validate();

			if (valid) {
				try {
					startLoading();

					const { data: { data: { id } } } = await KhoService.createNewWarehouse({
						ten: name.value,
						ma_kho: code.value,
						dia_chi: address.value,
						ghi_chu: note.value,
						id_tai_khoan: taiKhoanData.value.id,
						ma_chi_tiet: taiKhoanData.value.maChiTiet,
						ma_tai_khoan: taiKhoanData.value.maTaiKhoan,
						ten_tai_khoan: taiKhoanData.value.tenTaiKhoan,
					});

					await replace(`/quan-ly/kho/chi-tiet/${id}`)
					endLoading();
				} catch (err) {
					endLoading();
					await swalNotification(
						getErrorMsg(err, 'Có lỗi xảy ra khi tạo kho'),
						'error',
					)
				}
			}
		}

    return {
			ButtonsType, ButtonTypeColors,
      newWarehouseValidator,
      onSubmit,
      firstInput,
      name,
      nameErrorMess,
      code,
      codeErrorMess,
      address,
      addressErrorMess,
      note,
			goBack,
			taiKhoanData,
    };
  },

	methods: {
		onSelectTaiKhoan({
			taiKhoanItem: {
				id: id_tai_khoan,
				ma_tai_khoan,
				ma_chi_tiet,
				ten_tai_khoan,
			}
		}: {
			taiKhoanItem: ITaiKhoanResponseBase,
		}) {
			this.taiKhoanData.maTaiKhoan = ma_tai_khoan;
			this.taiKhoanData.tenTaiKhoan = ten_tai_khoan;
			this.taiKhoanData.id = id_tai_khoan;

			if (ma_chi_tiet) {
				this.taiKhoanData.maChiTiet = ma_chi_tiet.toString();
			}
		}
	}
});
